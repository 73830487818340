import { ScrollService } from './../services/scroll.service';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CONTACTS } from '../../../../../common/contacts.constants';
import { URL } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    URL = URL;
    contactList = CONTACTS.filter(c => c.country_code === 'cl');
    currentCountryCode = this.contactList[0].country_code;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private scrollService: ScrollService
    ) { }

    ngOnInit() {
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    getCurrentCountry() {
        const country = this.contactList.filter((c) => c.country_code === this.currentCountryCode);
        return country[0];
    }

    goToCart() {
        window.location.href = URL.CHILEFIRMAS_CART+'?subproduct=CF';
    }

    scrollTo(target: string): void {
        this.scrollService.updateTarget(target);
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
