import { CONTACTS } from './../../../../../common/contacts.constants';
import { Contact } from './../../../../../common/Contact';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID, ViewChild, ElementRef, Input } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ScrollService } from '../services/scroll.service';
import { URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomMenuComponent } from '../dialogs/dialog-custom-menu/dialog-custom-menu.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('triggerDialog') triggerDialog: ElementRef;
    @Input() promotionStrip: boolean;

    URL = URL;
    isShowNavMobyle = false;
    isShowHumburger = false;
    isShowNavDesktop = true;
    isActiveDropdownSupport: boolean;

    activeMenu = false;
    activeChats = false;
    activeCountry = false;
    activeContacts = false;
    isFadeIn = false;
    isOpenDialog = false;
    contactList: Contact[];

    @HostListener('window:scroll')
    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.checkHeaderPosition();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth < 670) {
            this.isShowNavDesktop = false;
            this.isShowHumburger = true;
            this.isShowNavMobyle = true;
        }
        else {
            this.isShowNavDesktop = true;
            this.isShowHumburger = false;
            this.isShowNavMobyle = false;
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private scrollService: ScrollService,
        private router: Router,
        public dialog: MatDialog,
        private _router: Router
    ) { }

    ngOnInit() {
        this.contactList = CONTACTS.filter(c => c.country_code === 'cl');

        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth < 670) {
                this.isShowNavDesktop = false;
                this.isShowHumburger = true;
                this.isShowNavMobyle = true;
            }
        }
    }

    checkHeaderPosition(): void {
        if (isPlatformBrowser(this.platformId)) {
            const offsetY = window.scrollY;
            const headerEl = this.header.nativeElement;
            if (offsetY >= 50 || this._document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')) {
                headerEl.classList.add('fixed');
            } else {
                headerEl.classList.remove('fixed');
            }
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    scrollTo(target: string) {
        this.router.navigate(['/']);
        setTimeout(() => this.scrollService.updateTarget(target), 200);
    }

    goToCart() {
        window.location.href = URL.CHILEFIRMAS_CART + '?subproduct=CF';
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    onClickOpenMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.add('open_menu');
    }

    onClickCloseMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.remove('open_menu');
        this.isActiveDropdownSupport = false;
    }

    menuToggle() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.activeMenu) {
                this.menuClose();
            } else {
                this.isFadeIn = true;
                setTimeout(() => {
                    this.activeMenu = true;
                }, 100);
            }
        }
    }

    openDialogChats() {
        if (isPlatformBrowser(this.platformId)) {
            this.activeMenu = false;
            const url = 'http://chat3.onlinesoporte.net/link/tow_index.php?bmVnb3RpdW0K=Q2hpbGVGaXJtYXM=&b3ZnZXRpdm8K=VmVudGEK';
            window.open(url, '', 'width=590,height=760,left=0,top=0,resizable=yes,menubar=no,location=no,status=yes,scrollbars=yes');
            return false;
        }
    }

    menuClose() {
        this.isFadeIn = false;
        this.activeMenu = false;
        this.isOpenDialog = false;
    }

    clickCloseMenu() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth > 1200) {
                this.isFadeIn = false;
                this.activeMenu = false;
            }
        }
    }

    mouseHideMenu() {
        if (this.isOpenDialog) {
            return;
        }
        this.menuClose();
    }

    menuFadeIn() {
        if (isPlatformBrowser(this.platformId)) {
            if (window.innerWidth > 1200) {
                this.activeMenu = true;
            }
        }
    }

    openDialogCustomMenu() {
        if (window.innerWidth > 670) {
            this.dialog.open(DialogCustomMenuComponent, {
                panelClass: 'dialogCustomMenu',
                width: '238px',
                backdropClass: 'transparent',
                position: {
                    top: '90px',
                    left: this.triggerDialog.nativeElement.getBoundingClientRect().right - 210 + 'px'
                }
            });
        }
    }

    // open dropdown menu of the mobile
    openDropdownMenu(menu) {
        if (menu === 'soporte') {
            this.isActiveDropdownSupport = true;
        }
    }

    // close dropdown menu of the mobile
    closeDropdownMenu(menu) {
        if (menu === 'soporte') {
            this.isActiveDropdownSupport = false;
        }
    }

    public getClassHeader() :string{
        let style = ''
        if (this.promotionStrip) {
            style = 'wrapHeader_strip'
        }
        if (this._router.url === '/contactar-con-ventas/.' || this._router.url === '/contactar-con-soporte/.') {
            style = style + 'non-sticky';
        }
        return style
    }
}
