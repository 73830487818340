import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-immediate-management',
    templateUrl: './immediate-management.component.html',
    styleUrls: ['./immediate-management.component.scss']
})
export class ImmediateManagementComponent implements OnInit {
    @Input() showImages: boolean;

    list = [
        {
            icon: 'pencil',
            title: 'Firmar PDF',
            text: 'Aprende como vincular tu firma para firmar documentos PDF'
        }, {
            icon: 'googl',
            title: 'Cargar tu firma en el navegador',
            text: 'Carga tu firma a Google Chrome o Firefox desde Mac o Windows.'
        }, {
            icon: 'unlock',
            title: 'Recuperar tu contraseña',
            text: 'Si no recuerdas la contraseña de tu firma recupérala aquí.'
        }, {
            icon: 'paper-plane',
            title: 'Reenviar tu firma',
            text: 'Si no consigues el archivo de tu firma electrónica reenvíalo a tu correo.'
        }
    ]

    constructor() { }

    ngOnInit(): void {
    }

}
