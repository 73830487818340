import { Component, OnInit, PLATFORM_ID, AfterViewInit, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';
import { ScrollService } from '../shared/services/scroll.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollToClass } from '../../../../common/scrollTo.class';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})

export class HomeComponent extends ScrollToClass implements OnInit, AfterViewInit {
    @ViewChild('animateSteps', { static: true }) animateSteps: ElementRef;
    isShowAnimationSteps: boolean = true;
    runSteps: boolean = false;
    numberSteps = Array<number>(5).fill(0);
    // for lazy lading images
    showImage1: boolean = false;
    showImage2: boolean = false;
    showImage3: boolean = false;
    showImage4: boolean = false;
    showImage5: boolean = false;
    //
    URL = URL;
    timerId;
    timerStep;
    advantageList = [
        {
            ttl: 'Gestión inmediata',
            text: 'Revisa nuestra página de auto-atención y resuelve todos los problemas y dudas que tengas sobre <br>tu firma electrónica.',
            icon: 'icon-sprite-advice',
            bg_color: 'bg_yellow'
        }, {
            ttl: 'Instalación inmediata',
            // tslint:disable-next-line:max-line-length
            text: 'Ingresa tus datos mientras los validamos en el registro civil y en menos de 3 minutos tendrás tu firma electrónica. Si tienes problemas con <br>la validación te asesoramos telefónicamente.',
            icon: 'icon-sprite-lightning',
            bg_color: 'bg_blue_light'
        }, {
            ttl: 'Garantía de 60 días de reembolso',
            text: 'Disfruta de nuestra garantía de reembolso mientras no hayas generado tu firma electrónica.',
            icon: 'icon-sprite-guarantee',
            bg_color: 'bg_blue_light2'
        }, {
            ttl: 'Recuperación <br> de firma',
            text: 'Si extraviaste tu firma electrónica. ¡No te preocupes! podemos recuperar tu archivo de forma rápida y sin costo alguno.',
            icon: 'icon-sprite-reload',
            bg_color: 'bg_green_light'
        }, {
            ttl: 'Garantía de <br> precios bajos',
            // tslint:disable-next-line:max-line-length
            text: 'La filosofía de nuestra empresa es dar acceso a todos a la tecnología. Por eso entregamos el mejor servicio y los mayores beneficios a precios asequibles, siempre.',
            icon: 'icon-sprite-percent',
            bg_color: 'bg_rose_light'
        }
    ];

    optionList = [
        {
            icon: 'icon-pallete',
            bg_color: 'bg_yellow',
            text: 'Personalización de Documentos'
        }, {
            icon: 'icon-templates',
            bg_color: 'bg_rose_light',
            text: 'Plantillas Pre-elaboradas'
        }, {
            icon: 'icon-megaphone',
            bg_color: 'bg_blue_light',
            text: 'Acuse Masivo de Documentos'
        }, {
            icon: 'icon-management',
            bg_color: 'bg_blue',
            text: 'Manejo de Folios Automáticos'
        }
    ];

    firmList = [
        {
            name: 'Firma.cl',
            bg: 'bg-icon-grey-dark',
            price: '11.900'
        }, {
            name: 'E-cert Chile',
            bg: 'bg-icon-grey',
            price: '14.160'
        }, {
            name: 'Paperless',
            bg: 'bg-icon-grey-light',
            price: '14.500'
        }
    ];

    sliderList = [
        {
            // tslint:disable-next-line:max-line-length
            review: 'Destaco de ChileFirmas la atención personalizada, además de esto me ha ayudado a reducir costos y facilitar mis operaciones tributarias al entregarme 1 año de OpenFactura gratis.',
            img: '/assets/images/img_user_comment.png',
            name: 'José Luis Ríos'
        }, {
            // tslint:disable-next-line:max-line-length
            review: 'Me encanta ChileFirmas, son muy profesionales y se preocupan del cliente. No solo me entregaron la firma, sino que también me ayudaron con la instalación.',
            img: '/assets/images/img_user_comment2.png',
            name: 'Adriana Gallegos'
        }, {
            // tslint:disable-next-line:max-line-length
            review: 'No pensé que obtener mi Firma Electrónica fuera tan fácil. ChileFirmas me dio todo el soporte que necesitaba de forma simple y oportuna.',
            img: '/assets/images/img_user_comment3.png',
            name: 'Isabel Alarcón'
        }, {
            // tslint:disable-next-line:max-line-length
            review: 'ChileFirmas siempre me ha dado un servicio estable y seguro que me ha permitido enfocarme en mi negocio. Ha sido un agrado trabajar con ellos.',
            img: '/assets/images/img_user_comment4.png',
            name: 'Rodrigo López'
        }
    ];

    priceList = [
        {
            id: 1,
            name: 'Firma Electrónica',
            amount: 1,
            price_pre: '14.790',
            price: '11.790',
            discount: '20',
            checked: false
        }, {
            id: 2,
            name: 'Firma Electrónica',
            amount: 2,
            price_pre: '19.790',
            price: '16.790',
            discount: '20',
            checked: false
        }, {
            id: 3,
            name: 'Firma Electrónica',
            amount: 3,
            price_pre: '22.790',
            price: '19.790',
            discount: '20',
            checked: true
        }
    ];

    listPrice = [
        {
            text: '<b>100% compatible </b>con SII',
            icon: 'icons-sii-blue',
            alt: 'sii'
        }, {
            text: '<b>Asesorías</b> exclusivas',
            icon: 'icon-headset-blue',
            alt: 'Asesorías exclusivas'
        }, {
            text: '<b>Recuperación</b> de firma',
            icon: 'icon-preload-blue',
            alt: 'Recuperación de firma'
        }, {
            text: '<b>60 Días de Garantía</b>',
            icon: 'icon-garanty60-blue',
            alt: '60 Días de Garantía'
        }, {
            text: '<b>Instalación remota</b>',
            icon: 'icon-settings-blue',
            alt: 'Instalación remota'
        }, {
            text: 'Garantía de <b>precios bajos</b>',
            icon: 'icon-percent-blue',
            alt: 'Garantía de precios bajos'
        }
    ];

    listSteps = [
        {
            id: 1,
            ttl: '<b>ADQUIERE</b> tu firma en ChileFirmas.cl'
        }, {
            id: 2,
            ttl: '<b>INGRESA</b> al enlace que te enviaremos por correo electrónico'
        }, {
            id: 3,
            ttl: '<b>GENERA</b> tu Firma electrónica ingresando los datos solicitados y creando la contraseña'
        }, {
            id: 4,
            ttl: '<b>DESCARGA</b> tu Firma electrónica al finalizar el proceso y recibe un respaldo a tu correo electrónico'
        }
    ];

    @ViewChild('prices', { static: true }) public prices: ElementRef;
    @ViewChild('partner', { static: true }) public partner: ElementRef;
    @ViewChild('fq', { static: true }) public fq: ElementRef;

    form_1: FormGroup;
    form_2: FormGroup;

    @HostListener('window:scroll')
    onScroll(): void {
        this.addClassAnimationSteps();
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private readonly _meta: MetaService,
        private scrollService: ScrollService,
        private formBuilder_1: FormBuilder,
        private formBuilder_2: FormBuilder,
        private router: Router,
        private _el: ElementRef
    ) {
        super(_el);
    }

    ngOnInit() {
        this._meta.setTitle('FIRMA ELECTRÓNICA | Adquiere tu Firma Electrónica desde $11.790');
        this._meta.setTag('description', '¿Deseas facturar electrónicamente? en ChileFirmas' +
        ' puedes adquirir tu Firma Electrónica Simple con la asistencia personalizada de nuestros ejecutivos.');
        this._meta.setTag('keywords', 'Firma electrónica; firma electrónica chile, firma electrónica SII, firma electrónica simple');
        this._meta.setTag('author', 'ChileFirmas');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.CHILEFIRMAS_WEBSITE + 'assets/images/OGChilefirmasok.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', '¿Deseas facturar electrónicamente? en ChileFirmas' +
        'puedes adquirir tu Firma Electrónica Simple a los precios más accesibles del mercado.');
        this._meta.setTag('og:locale', 'es_CL');


        if (isPlatformBrowser(this.platformId)) {
            this.removeBlueLightBackground();
        }

        this.form_1 = this.formBuilder_1.group({
            option: ['3', [Validators.required]]
        });


        this.form_2 = this.formBuilder_2.group({
            option: ['3', [Validators.required]]
        });

        this.scrollService.getMessage().subscribe(target => this.scrollTo(target));
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.initSlider();
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    scrollTo(target: string) {
        if (target === 'PR' && !!this.prices) {
            this.prices.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else if (target === 'PA' && !!this.partner) {
            this.partner.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        } else if (target === 'FQ' && !!this.fq) {
            this.fq.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
    }

    addClassAnimationSteps() {
        const steps = this.animateSteps.nativeElement;

        if (this.isInViewport(steps)) {
            if (this.runSteps) {
                return;
            }
            steps.classList.add('animate-steps');

            const totalSteps = 6;
            let currentStep = 0;

            this.timerStep = setInterval(() => {
                if (currentStep === totalSteps) {
                    return;
                }
                if (currentStep < 5) {
                    currentStep += 1;
                    steps.classList.add('step-' + currentStep);
                } else {
                    currentStep = 0;
                    for (let i = 1; i <= 6; i++) {
                        steps.classList.remove('step-' + i);
                    }
                }
            }, 1000);

            this.runSteps = true;
        } else {
            steps.classList.remove('animate-steps');
            this.runSteps = false;
            clearInterval(this.timerStep);
            for (let i = 0; i <= 5; i++) {
                steps.classList.remove('step-' + i);
            }
        }
    }

    removeBlueLightBackground() {
        const headerEl = <HTMLElement>this._document.getElementsByClassName('wrapHeader')[0];
        if (headerEl.classList.contains('bg_blue')) {
            headerEl.classList.remove('bg_blue');
        }
    }

    isInViewport(elem) {
        if (isPlatformBrowser(this.platformId)) {
            const bounding = elem.getBoundingClientRect();

            return (
                bounding.top <= (window.innerHeight - window.innerHeight * 0.2)
            );
        }
    }

    initSlider() {
        const slider = tns({
            container: '.sliderReviews',
            items: 2,
            autoWidth: true,
            speed: 400,
            loop: true,
            nav: true,
            controls: false,
            responsive: {
                350: {
                    items: 1,
                },
                1200: {
                    items: 2,
                }
            },
        });
    }

    onSubmit(f: number) {

        let period_opt = 1;

        if (f === 1) {
            period_opt = parseInt(this.form_1.value['option'], 10);
        } else if (f === 2) {
            period_opt = parseInt(this.form_2.value['option'], 10);
        }

        window.location.href = URL.CHILEFIRMAS_CART + '?subproduct=CF&period_opt=' + period_opt;
    }

    goToCart() {
        window.location.href = URL.CHILEFIRMAS_CART+'?subproduct=CF';
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }
}
